/**
 * Функция проверяет сколько еще доступно места в LocalStorage, возвращает ответ в байтах
 * @returns
 */
export const getFreeSpaceLocalStorage = () => {
  let testKey = "test";
  let size = 0;
  try {
    while (true) {
      localStorage.setItem(testKey + size, "x".repeat(1024));
      size += 1024;
    }
  } catch (e) {
    for (let i = 0; i <= size / 1024; i++) {
      localStorage.removeItem(testKey + i * 1024);
    }
    return size;
  }
};
