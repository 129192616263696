import { getFreeSpaceLocalStorage } from "./getFreeSpaceLocalStorage";
import { getObjectSizeInBytes } from "./getObjectSizeInBytes";
import { getOldSaveArray } from "./getOldSaveArray";
/**
 * Функция проверяет и отчищает (при необходимости) LocalStorage от старых проектов, что бы записать новый.
 * @param {Object} obj - объект который необходимо сохранить
 */
export const clearNecessarySpaceForSave = (obj) => {
  const objectSize = getObjectSizeInBytes(obj);
  const freeSpace = getFreeSpaceLocalStorage();
  const needSpace = freeSpace - objectSize;
  if (needSpace < 0) {
    const OldSaveArray = getOldSaveArray();
    const localSaveProject = JSON.parse(
      localStorage.getItem("localSaveProject")
    );
    for (const save of OldSaveArray) {
      const saveSize = getObjectSizeInBytes(localSaveProject[save.key]);
      delete localSaveProject[save.key];
      if (Math.abs(needSpace) - saveSize < 0) {
        break;
      }
    }
    localStorage.setItem("localSaveProject", JSON.stringify(localSaveProject));
  }
};
