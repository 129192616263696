/**
 * Функция возвращает массив объектов (с свойствами key - ключ сохранения, date - время последнего обращения)
 * отсортированное по давности обращения, самый старый будет первым элементом массива.
 * @returns
 */
export const getOldSaveArray = () => {
  const localSaveProject = JSON.parse(localStorage.getItem("localSaveProject"));
  if (!localSaveProject) {
    return null;
  }
  const returnObj = {};
  for (const key in localSaveProject) {
    if (!localSaveProject[key].plan) {
      continue;
    }
    returnObj[key] = { key, date: localSaveProject[key].date || Infinity };
  }
  return Object.values(returnObj).sort((a, b) => a.date - b.date);
};
