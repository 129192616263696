import { call, put, select, takeEvery } from "redux-saga/effects";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import Module from "../Classes/Module";
import Plan from "../Classes/Plan";
import {
  calculateMaterialAmount,
  calculateMaterialQuantity,
  roundValue,
} from "../Helpers/estimate";
import { estimateActions } from "../Redux/estimate";
import { putMaterials } from "../Redux/material";
import { actionsState as modulesState } from "../Redux/modules";
import { actionsState as projectState } from "../Redux/project";
import { clearNecessarySpaceForSave } from "../services/localStorage/clearNecessarySpaceForSave";

const getStaticData = async () => {
  try {
    const result = await fetch(window.confAjaxUrl, {
      method: "POST",
      body: JSON.stringify({ method: "getStaticData", token: window.rr_token }),
      headers: {
        "Content-Type": "application/json",
        application: "x-www-form-urlencoded",
      },
    })
      .then((result) => result.json())
      .catch((e) => console.log(e));

    if (!result?.data) {
      console.log("Возникла проблема с fetch запросом: getStaticData");
      return { ERR: "nodecodetoken" };
    }

    const { data } = result;

    return {
      cancelTouch: data?.interfaceOptions?.cancelTouch,
      help: data?.sd_help_links,
      estimators: data?.sd_job_estimators,
      estimate: data?.sd_job_list,
      estimateMaterials: data?.sd_job_materials,
      materials: data?.sd_materials,
      modules: data?.sd_sleeping_modules,
    };
  } catch (e) {
    console.log("Возникла проблема с fetch запросом: ", e.message);
    return { ERR: "nodecodetoken" };
  }
};

function* getData() {
  console.time("getData() took time");
  console.info("Получаем данные...");

  yield put(
    projectState.loadCompleted({
      isCompleted: false,
      startTime: new Date(),
    })
  );

  if (!window.rr_token) {
    yield put(projectState.setModal("notoken"));
    yield put(projectState.decPreloader());
    return;
  }

  const {
    help,
    estimators,
    estimate,
    estimateMaterials,
    materials,
    modules,
    cancelTouch,
  } = yield getStaticData();

  if (help.status === "ok") {
    yield put(modulesState.putHelpLinks(help.data));
  }

  yield put(modulesState.getPrice());
  yield put(modulesState.putAllModules(modules.sleepingModules));
  yield put(modulesState.putSections(modules.sections));

  const preparedMaterials = yield call(getMaterials, { result: materials });
  window.materials = preparedMaterials;

  yield put({
    type: putMaterials,
    value: {
      corpMaterials: preparedMaterials.corp,
      activeCorpMaterial:
        preparedMaterials.corp.length > 0 ? preparedMaterials.corp[0] : null,
      faceMaterials: preparedMaterials.face,
      activeFaceMaterial:
        preparedMaterials.face.length > 0 ? preparedMaterials.face[0] : null,
      clothMaterials: preparedMaterials.cloth,
      activeClothMaterial:
        preparedMaterials.cloth.length > 0 ? preparedMaterials.cloth[0] : null,
      floorMaterial: preparedMaterials.floor,
      activeFloorMaterial:
        preparedMaterials.floor.length > 0 ? preparedMaterials.floor[0] : null,
      wallMaterial: preparedMaterials.wall,
      activeWallMaterial:
        preparedMaterials.wall.length > 0 ? preparedMaterials.wall[0] : null,
      activeWallRMaterial:
        preparedMaterials.wall.length > 0 ? preparedMaterials.wall[0] : null,
      activeWallLMaterial:
        preparedMaterials.wall.length > 0 ? preparedMaterials.wall[0] : null,
    },
  });

  const token = yield loadProjectData({});
  yield put(modulesState.getPrice());

  yield call(prepareEstimate, {
    estimate,
    estimators,
    estimateMaterials,
    userId: token?.user_id,
  });

  yield put(projectState.setCancelTouch(Boolean(cancelTouch)));
  yield put(projectState.decPreloader());
  yield put(
    projectState.loadCompleted({
      isCompleted: true,
      startTime: null,
    })
  );

  console.info("Данные получили.");
  console.timeEnd("getData() took time");
}

function* loadProjectVariant({ hash, variant }) {
  yield put(
    projectState.loadCompleted({
      isCompleted: false,
      startTime: new Date(),
    })
  );
  yield put(projectState.addPreloader());

  yield loadProjectData({ hash, variant });

  yield put(projectState.decPreloader());
  yield put(
    projectState.loadCompleted({
      isCompleted: true,
      startTime: null,
    })
  );
}

function* publishVariant() {
  yield put(projectState.addPreloader());

  // Добавим обязательно сохранение текущих данных варианта перед публикацией
  yield call(saveProject, {
    modal: true,
    message: "Проект успешно опубликован",
  });

  const variants = yield select((store) => store.project.variants);

  const resp = yield fetch(window.confAjaxUrl, {
    method: "POST",
    body: JSON.stringify({ method: "publishVariant", token: window.rr_token }),
    headers: {
      "Content-Type": "application/json",
      application: "x-www-form-urlencoded",
    },
  });
  const answer = yield resp.json();
  if (answer?.success) {
    variants.forEach((variant) => {
      if (variant.CUR_VARIANT) {
        variant.PUBLISHED = true;
        variant.PUBLISHED_DATE = answer.PUBLISHED_DATE;
      }
    });
    yield put(projectState.setVariants(variants));
  }

  // todo: Заменить после появления API
  const curVariant = variants.filter((variant) => variant.CUR_VARIANT)[0];
  yield call(loadUserSetup, {
    setup: curVariant?.PUBLISHED ? "published" : "default",
  });

  yield put(projectState.decPreloader());
}

function* loadProjectData({ hash, variant }) {
  const resp = yield loadProject(hash, variant);
  const token = resp.tokenData;

  if (window.rr_token) {
    if (token?.ERR) {
      yield put(projectState.setModal(token.ERR));
      yield put(projectState.decPreloader());
      return;
    }
  } else {
    yield put(projectState.setModal("notoken"));
    yield put(projectState.decPreloader());
    return;
  }

  const role = window.fixedRole ? window.fixedRole : token?.role;
  const modalMessage = yield select(
    (store) => store.project.saveResult.message
  );

  if (token) {
    const tokenRID = token.variant_id
      ? `${token.request_id}.${token.variant_id}`
      : token.request_id;

    yield put(
      projectState.setSaveResult({
        token: window.rr_token,
        tokenAnswer: token,
        tokenRID: tokenRID,
        tokenUID: token.user_id,
        tokenROLE: role,
        tokenEID: token.estimate_id,
        message: modalMessage,
      })
    );
  }

  if (resp.status === "fail") {
    const variants = [
      {
        CRM_RIGHTS: yield select((store) => store.project.saveResult.tokenROLE),
        CRM_USER: yield select((store) => store.project.saveResult.tokenUID),
        CUR_VARIANT: true,
        ID: null,
        NAME: "Проект по заявке №" + hash,
        PUBLISHED: false,
        PUBLISHED_DATE: "",
        XML_ID: hash,
      },
    ];
    yield put(projectState.setVariants(variants));
    yield put(projectState.decPreloader());
    return;
  }

  if (resp?.need_change_token) {
    hash = resp?.cur_variant_id;
    window.history.replaceState(
      {},
      document.title,
      `${window.origin}/?token=${resp?.hash}`
    );
    window.rr_token = resp?.hash;
    yield put(
      projectState.setSaveResult({
        token: resp?.hash,
        tokenAnswer: token,
        tokenRID: resp?.cur_variant_id,
        tokenUID: token.user_id,
        tokenROLE: role,
        tokenEID: token.estimate_id,
        message: modalMessage,
      })
    );
  }
  clearNecessarySpaceForSave({ ...resp.object, date: Date.now() });
  localStorage.setItem(
    "localSaveProject",
    JSON.stringify({
      ...JSON.parse(localStorage.getItem("localSaveProject")),
      app: "ReRooms",
      version: "0.1.0",
      [hash]: { ...resp.object, date: Date.now() },
    })
  );
  const plan = new Plan();
  plan.loadFromOBJ(resp.object.plan);
  const { offsetX, offsetY, zoom } = plan.getCommonPlanPosition();
  plan.offsetX = offsetX;
  plan.offsetY = offsetY;
  plan.zoom = zoom;
  plan.minZoom = 6;
  plan.maxZoom = Math.min(zoom - 0.01, 0.01);
  plan.restored = true;

  yield put(estimateActions.setCoefficient(resp.object?.coefficient || 1));
  yield put(modulesState.clearInstanceModules());
  yield put(projectState.setPlan(plan));
  yield put(projectState.setVersions(resp.all_versions));
  yield put(projectState.setVariants(resp.all_variants));
  if (resp.object?.plan?.imageBG) {
    resp.object.plan.imageBG.isEditing = false;
    yield put(projectState.setImageBG(resp.object.plan.imageBG));
  }

  // todo: Заменить после появления API
  const variants = yield select((store) => store.project.variants);
  const curVariant = variants.filter((variant) => variant.CUR_VARIANT)[0];
  if (token.role == "client") {
    yield call(loadUserSetup, { setup: "client" });
  } else if (curVariant?.PUBLISHED) {
    yield call(loadUserSetup, { setup: "published" });
  } else {
    yield call(loadUserSetup, { setup: "default" });
  }

  const allModules = yield select((state) => state.modules.allModules);
  for (const mod of resp.object.objects) {
    const shema = allModules.find((m) => m.ID == mod.id);
    if (!shema) {
      console.error("_obj ERROR mod.id", mod.id);
    } else {
      const m = yield call(loadModule, shema);
      m.position = new THREE.Vector2(mod.position.x, mod.position.y);
      m.heightFromFloor = mod.heightFromFloor ? mod.heightFromFloor : 0;
      m.size = mod.size ? mod.size : false;
      m.sizeDef = mod.sizeDef ? mod.sizeDef : false;
      m.angle = mod.angle ? mod.angle : 0;

      m.model.scale.x = m.size.sizeX / (m.sizeDef.sizeX / 1000) / 1000;
      m.model.scale.y = m.size.sizeY / (m.sizeDef.sizeY / 1000) / 1000;
      m.model.scale.z = m.size.sizeZ / (m.sizeDef.sizeZ / 1000) / 1000;

      m.objTitle = mod.objTitle;
      m.objComment = mod.objComment;
      m.objImages = mod.objImages;
      m.rgbColor = mod.rgbColor;
      m.rgb = mod.rgb;
      m.estimate = mod?.estimate || [];

      if (mod.rgb.b !== false && mod.rgb.g !== false && mod.rgb.r !== false) {
        m.model.traverse((property) => {
          if (property.isMesh) {
            property.material.color.r = mod.rgb.r / 255;
            property.material.color.g = mod.rgb.g / 255;
            property.material.color.b = mod.rgb.b / 255;
          }
        });
      }

      m.optionStatus = mod.option ? mod.option : false;
      const corp = window.materials.corp.find(
        (m) => m.userData.ID === mod.corp
      );
      const face = window.materials.face.find(
        (m) => m.userData.ID === mod.face
      );
      const cloth = window.materials.cloth.find(
        (m) => m.userData.ID === mod.cloth
      );
      m.corp = corp ? corp.clone() : window.materials.corp[0].clone();
      m.face = face ? face.clone() : window.materials.face[0].clone();

      m.cloth = cloth ? cloth.clone() : window.materials.cloth[0].clone();
      m.size = mod.size ? mod.size : false;
      m.sizeDef = mod.sizeDef ? mod.sizeDef : false;

      yield put(modulesState.addInstanceModule(m));
    }
  }

  return token;
}

async function loadProject(hash, variant) {
  try {
    const uri = window.location.search.substring(1);
    const params = new URLSearchParams(uri);
    const version_id = params.get("vid");
    window.history.pushState(
      {},
      document.title,
      removeParam("vid", window.location.href)
    );

    const resp = await fetch(window.confAjaxUrl, {
      method: "POST",
      body: JSON.stringify({
        method: "loadProject",
        hash,
        token: window.rr_token,
        variant_id: variant,
        version_id: version_id,
      }),
      headers: {
        "Content-Type": "application/json",
        application: "x-www-form-urlencoded",
      },
    });
    return resp.json();
  } catch (error) {
    projectState.setModal("errorload");
  }
}

function* changeModule({ module, variant }) {
  yield put(projectState.addPreloader());
  const m = yield call(loadModule, variant);
  m.angle = module.angle;
  m.position = module.position.clone();
  m.optionStatus = module.optionStatus;
  if (
    variant.MATERIALS.corp === null ||
    variant.MATERIALS.corp[module.corp.userData.ID]
  ) {
    m.corp = module.corp.clone();
  }
  if (
    variant.MATERIALS.face === null ||
    variant.MATERIALS.face[module.face.userData.ID]
  ) {
    m.face = module.face.clone();
  }
  if (
    variant.MATERIALS.cloth === null ||
    variant.MATERIALS.cloth[module.cloth.userData.ID]
  ) {
    m.cloth = module.cloth.clone();
  }
  const modules = yield select((store) => store.modules.modules);
  const newModules = modules.map((el) => {
    if (el === module) return m;
    else return el;
  });
  yield put(modulesState.putModules(newModules));
  yield put(modulesState.updateModule());
  yield put(modulesState.getPrice());
  yield put(projectState.decPreloader());
}

function* addModule(action) {
  yield put(projectState.addPreloader());
  const module = action.module;
  const m = yield call(loadModule, module);

  if (!action.refModule) {
    const activeCorpMaterial = yield select(
      (store) => store.material.activeCorpMaterial
    );
    const activeFaceMaterial = yield select(
      (store) => store.material.activeFaceMaterial
    );
    const activeClothMaterial = yield select(
      (store) => store.material.activeClothMaterial
    );
    m.corp = activeCorpMaterial.clone();
    m.face = activeFaceMaterial.clone();
    m.cloth = activeClothMaterial.clone();
  } else {
    if (
      action.refModule.corp &&
      typeof action.refModule.corp.clone === "function"
    )
      m.corp = action.refModule.corp.clone();
    if (
      action.refModule.face &&
      typeof action.refModule.face.clone === "function"
    )
      m.face = action.refModule.face.clone();
    if (
      action.refModule.cloth &&
      typeof action.refModule.cloth.clone === "function"
    )
      m.cloth = action.refModule.cloth.clone();

    m._position = action.refModule._position.clone();
    m.position = action.refModule.position.clone();
    m.angle = action.refModule.angle;
    m.heightFromFloor = action.refModule.heightFromFloor;
    m.shema = {
      normal: action.refModule.shema.normal,
      hover: action.refModule.shema.hover,
    };
  }

  if (action.module.newSize) {
    m.size = action.module.newSize;
    m.model.scale.x =
      action.module.newSize.sizeX / (action.module.sizeDef.sizeX / 1000) / 1000;
    m.model.scale.y =
      action.module.newSize.sizeY / (action.module.sizeDef.sizeY / 1000) / 1000;
    m.model.scale.z =
      action.module.newSize.sizeZ / (action.module.sizeDef.sizeZ / 1000) / 1000;

    m.updateShema();

    m.size = action.module.newSize;
    m.sizeDef = action.module.sizeDef;
    m.optionStatus = false;
  }

  yield put(modulesState.addInstanceModule(m));
  yield put(modulesState.getPrice());
  yield put(projectState.decPreloader());
}

const saveProject = function* ({
  modal = true,
  isVariant = false,
  variantName = "",
  message = "",
}) {
  console.time("saveProject() took time");
  console.info("Сохраняем проект...");
  yield put(
    projectState.loadCompleted({
      isCompleted: false,
      startTime: new Date(),
    })
  );
  yield put(projectState.addPreloader());

  let needGetData = false;
  const plan = yield select((store) => store.project.plan);
  const coefficient = yield select((store) => store.estimate.coefficient);
  const modules = yield select((store) => store.modules.modules);
  const tokenRID = yield select((store) => store.project.saveResult.tokenRID);
  const imageBG = yield select((state) => state.project.ImageBG);

  const objects = modules.map((m) => ({
    id: m.id,
    ordinalNumber: m.ordinalNumber,
    position: { x: m.position.x, y: m.position.y },
    size: m.size,
    sizeDef: m.sizeDef,
    heightFromFloor: m.heightFromFloor,
    angle: m.angle,
    corp: m.corp ? m.corp.userData.ID : null,
    face: m.face ? m.face.userData.ID : null,
    cloth: m.cloth ? m.cloth.userData.ID : null,
    option: m.optionStatus,

    objTitle: m.objTitle,
    objComment: m.objComment,
    objImages: m.objImages,
    estimate: m?.estimate || [],

    rgb: m.rgb,
    rgbColor: m.rgbColor,
  }));

  const project = {
    plan: { ...plan.toOBJ(), imageBG },
    objects,
    coefficient,
    date: Date.now(),
  };

  const currentLocalProject = localStorage.getItem("localSaveProject")
    ? JSON.parse(localStorage.getItem("localSaveProject"))[tokenRID]
    : null;

  let localSavePlan;

  if (currentLocalProject) {
    // проверяем локальное хранилище
    localSavePlan = currentLocalProject;
    localSavePlan.offsetX = project.offsetX;
    localSavePlan.offsetY = project.offsetY;
    localSavePlan.zoom = project.zoom;
    localSavePlan.plan.offsetX = project.plan.offsetX;
    localSavePlan.plan.offsetY = project.plan.offsetY;
    localSavePlan.plan.zoom = project.plan.zoom;
    localSavePlan.plan.canvasCenter.x = project.plan.canvasCenter.x;
    localSavePlan.plan.canvasCenter.y = project.plan.canvasCenter.y;
  }
  clearNecessarySpaceForSave(project);
  localStorage.setItem(
    "localSaveProject",
    JSON.stringify({
      ...JSON.parse(localStorage.getItem("localSaveProject")),
      app: "ReRooms",
      version: "0.1.0",
      [tokenRID]: project,
    })
  );
  const resp = yield fetch(window.confAjaxUrl, {
    method: "POST",
    body: JSON.stringify({
      method: "saveProject",
      project,
      token: window.rr_token,
      variant_new: isVariant,
      project_name: variantName,
    }),
    headers: {
      "Content-Type": "application/json",
      application: "x-www-form-urlencoded",
    },
  });
  const answer = yield resp.json();

  if (answer?.need_change_token) {
    window.history.replaceState(
      {},
      document.title,
      `${window.origin}/?token=${answer?.hash}`
    );
    window.rr_token = answer?.hash;
    needGetData = true;
  }

  if (answer.status !== "ok") {
    yield put(projectState.setErrorCount(1));
  } else yield put(projectState.setErrorCount(0));

  const modalMessage =
    message ||
    (isVariant ? "Вариант проекта успешно создан" : "Проект успешно сохранен");

  yield put(
    projectState.setSaveResult({
      status: answer.status,
      hash: answer.hash,
      href: answer.href,
      token: window.rr_token,
      tokenAnswer: answer[0],
      tokenRID: answer[0].variant_id
        ? `${answer[0].request_id}.${answer[0].variant_id}`
        : answer[0].request_id,
      tokenUID: answer[0].user_id,
      tokenROLE: answer[0].role,
      tokenEID: answer[0].estimate_id,
      message: modalMessage,
    })
  );
  if (modal) {
    yield put(projectState.setModal("save"));
  }

  if (needGetData) {
    yield call(loadProjectData, {
      hash: yield select((store) => store.project.saveResult.hash),
      variant: yield select((store) => store.project.saveResult.tokenRID),
    });
  }

  yield put(projectState.decPreloader());
  console.info("Сохранение завершено.");
  console.timeEnd("saveProject() took time");

  yield put(
    projectState.loadCompleted({
      isCompleted: true,
      startTime: null,
    })
  );
};

//TODO: Заметил, что при переключении варианта проекта срабатыает 1 лишнее автосохранение. Возможно при загрузке варианта, нужно еще обновить localStorage
const autoSaveProject = function* () {
  yield put(
    projectState.loadCompleted({
      isCompleted: false,
      startTime: new Date(),
    })
  );

  const plan = yield select((store) => store.project.plan);
  const modules = yield select((store) => store.modules.modules);
  const coefficient = yield select((store) => store.estimate.coefficient);
  const tokenRID = yield select((store) => store.project.saveResult.tokenRID);
  const imageBG = yield select((state) => state.project.ImageBG);

  const objects = modules.map((m) => ({
    id: m.id,
    ordinalNumber: m.ordinalNumber,
    position: { x: m.position.x, y: m.position.y },
    size: m.size,
    sizeDef: m.sizeDef,
    heightFromFloor: m.heightFromFloor,
    angle: m.angle,
    corp: m.corp ? m.corp.userData.ID : null,
    face: m.face ? m.face.userData.ID : null,
    cloth: m.cloth ? m.cloth.userData.ID : null,
    option: m.optionStatus,

    objTitle: m.objTitle,
    objComment: m.objComment,
    objImages: m.objImages,

    rgb: m.rgb,
    rgbColor: m.rgbColor,
  }));

  const project = {
    plan: { ...plan.toOBJ(), imageBG },
    objects,
    coefficient,
    date: Date.now(),
  };

  const currentLocalProject = localStorage.getItem("localSaveProject")
    ? JSON.parse(localStorage.getItem("localSaveProject"))[tokenRID]
    : null;

  if (JSON.stringify(project) !== JSON.stringify(currentLocalProject)) {
    let localSavePlan;

    if (currentLocalProject) {
      // проверяем локальное хранилище
      localSavePlan = currentLocalProject;
      localSavePlan.offsetX = project.offsetX;
      localSavePlan.offsetY = project.offsetY;
      localSavePlan.zoom = project.zoom;
      localSavePlan.plan.offsetX = project.plan.offsetX;
      localSavePlan.plan.offsetY = project.plan.offsetY;
      localSavePlan.plan.zoom = project.plan.zoom;
      localSavePlan.plan.canvasCenter.x = project.plan.canvasCenter.x;
      localSavePlan.plan.canvasCenter.y = project.plan.canvasCenter.y;
    }
    clearNecessarySpaceForSave(project);

    if (JSON.stringify(localSavePlan) === JSON.stringify(project)) {
      localStorage.setItem(
        "localSaveProject",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("localSaveProject")),
          app: "ReRooms",
          version: "0.1.0",
          [tokenRID]: project,
        })
      ); // * сохраняем локально
    } else {
      localStorage.setItem(
        "localSaveProject",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("localSaveProject")),
          app: "ReRooms",
          version: "0.1.0",
          [tokenRID]: project,
        })
      ); // * сохраняем локально
      const resp = yield fetch(window.confAjaxUrl, {
        // * сохраняем на сервер
        method: "POST",
        body: JSON.stringify({
          method: "saveProject",
          project,
          token: window.rr_token,
          autosave: true,
        }),
        headers: {
          "Content-Type": "application/json",
          application: "x-www-form-urlencoded",
        },
      });
      const answer = yield resp.json();

      if (answer.status !== "ok") {
        yield put(projectState.setErrorCount(1));
      } else yield put(projectState.setErrorCount(0));

      yield put(
        projectState.setSaveResult({
          status: answer.status,
          hash: answer.hash,
          href: answer.href,
          token: window.rr_token,
          tokenAnswer: answer[0],
          tokenRID: answer[0].variant_id
            ? `${answer[0].request_id}.${answer[0].variant_id}`
            : answer[0].request_id,
          tokenUID: answer[0].user_id,
          tokenROLE: answer[0].role,
          tokenEID: answer[0].estimate_id,
        })
      );
    }
  }

  yield put(
    projectState.loadCompleted({
      isCompleted: true,
      startTime: null,
    })
  );
};

const prepareEstimate = function* ({
  estimate,
  estimators,
  estimateMaterials,
  userId,
}) {
  try {
    const estimator = estimators.find((estimator) => estimator.id === userId);

    let tariffs = Object.keys(estimate);
    if (estimator?.tarifs) {
      tariffs = tariffs.filter((tariff) =>
        estimator.tarifs.find((element) => element.name === tariff)
      );
    }

    const formatData = [];
    const objectsByTariff = {};

    if (estimate) {
      for (const tariff in estimate) {
        const jobsByObject = estimate[tariff];
        objectsByTariff[tariff] = Object.keys(jobsByObject).map((type) => ({
          label: type,
          value: type,
        }));
        for (const object in jobsByObject) {
          const jobsByType = jobsByObject[object];
          for (const type in jobsByType) {
            const jobs = jobsByType[type];
            formatData.push(
              ...jobs.map((job) => ({
                id: job?.ID,
                externalId: job?.UF_EXTERNAL_ID,
                code: job?.UF_CODE,
                cost: +job?.UF_PRICE,
                name: job?.UF_NAME,
                type: job?.UF_TYPE_VALUE,
                unit: job?.UF_UNIT,
                group: job?.UF_GROUP_CODE,
                price: job?.UF_PRICE,
                stage: job?.UF_STAGE_CODE,
                tariff: job?.UF_TARIFF_VALUE,
                materials: job?.UF_MATERIAL_RELATIONS.map((material) => {
                  const materialJob =
                    estimateMaterials[material?.UF_MATERIAL_ID - 1];
                  return {
                    id: materialJob?.ID,
                    externalId: materialJob?.UF_EXTERNAL_ID,
                    name: materialJob?.UF_NAME,
                    code: materialJob?.UF_CODE,
                    type: materialJob?.UF_CATEGORY?.UF_TYPE_VALUE,
                    cost: +materialJob?.UF_PRICE,
                    price: Number(materialJob?.UF_PRICE)?.toFixed(1),
                    unit: materialJob?.UF_UNIT,
                    norm: +material?.UF_NORM,
                    category_type: materialJob?.UF_CATEGORY?.UF_TYPE_CODE,
                    rounding_type: materialJob?.UF_ROUNDING_TYPE_CODE,
                    rationing_type: materialJob?.UF_RATIONING_TYPE_CODE,
                    ujob: materialJob?.UF_UNIT_JOB,
                    tara: materialJob?.UF_TARA,
                  };
                }),
                object,
              }))
            );
          }
        }
      }
    }

    yield call(updateCurrentEstimate, { allEstimate: formatData });
    yield put(estimateActions.putEstimate(formatData));

    const tariffList = tariffs.map((tariff) => ({
      label: tariff,
      value: tariff,
    }));
    const currentTariff =
      estimator?.tariff && estimator?.tariff?.name
        ? { label: estimator.tariff.name, value: estimator.tariff.name }
        : tariffList[0];

    yield put(
      estimateActions.putTariffs({
        tariffs: tariffList,
        currentTariff,
        objectsByTariff,
      })
    );
  } catch (error) {
    console.log("Возникла проблема с fetch запросом: ", error.message);
  }
};

const updateCurrentEstimate = function* ({ allEstimate }) {
  const plan = yield select((store) => store.project.plan);
  const modules = yield select((state) => state.modules.modules);
  const coefficient = yield select((state) => state.estimate.coefficient);

  const { bWalls, cycles } = plan;

  const updateData = (estimate, object) => {
    const currentEstimate = allEstimate.find((data) => data.id === estimate.id);
    if (currentEstimate) {
      let materials =
        estimate?.materials && estimate.materials?.length
          ? estimate?.materials
          : currentEstimate.materials;

      materials = materials
        .map((material) => {
          const currentEstimateMaterial = currentEstimate.materials.find(
            (element) => element.id === material.id
          );
          if (!currentEstimateMaterial) return null;

          material.volume = estimate.volume;
          material.quantity = calculateMaterialQuantity(estimate, material);
          material.cost = currentEstimateMaterial.cost;
          material.price = currentEstimateMaterial.price;
          material.amount = calculateMaterialAmount(material);

          return {
            ...currentEstimateMaterial,
            ...material,
          };
        })
        .filter((material) => material !== null);

      return {
        ...currentEstimate,
        ...estimate,
        materials,
        ...(object?.isCycle ? { level: estimate?.level || "floor" } : {}),
        group: currentEstimate.group || estimate.group,
        stage: currentEstimate.stage || estimate.stage,
        amount: roundValue(
          currentEstimate.cost * estimate.volume * (coefficient || 1)
        ),
        cost: roundValue(currentEstimate.cost * (coefficient || 1)),
      };
    }
    return estimate;
  };

  bWalls.forEach((wall) => {
    if (wall.estimate && wall.estimate?.length) {
      wall.estimate = wall.estimate.map(updateData);
    }
    wall.objects.forEach((object) => {
      if (object.estimate && object.estimate?.length) {
        object.estimate = object.estimate.map(updateData);
      }
    });
  });

  cycles.forEach((cycle) => {
    if (cycle.estimate && cycle.estimate?.length) {
      cycle.estimate = cycle.estimate.map((estimate) =>
        updateData(estimate, cycle)
      );
    }
  });

  modules.forEach((module) => {
    if (module.estimate && module.estimate?.length) {
      module.estimate = module.estimate.map(updateData);
    }
  });

  yield put(projectState.setPlan(plan));
};

const loadUserSetup = function* ({ setup = "default" }) {
  try {
    // todo: Вернуть после появления API
    // const resp = yield fetch(window.confAjaxUrl, {
    //     method: 'POST',
    //     body: JSON.stringify({ method: "loadUserSetup", token: window.rr_token }),
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'application': 'x-www-form-urlencoded'
    //     }
    // });
    // const answer = yield resp.json();

    // return resp.json();

    //
    let userSetup = {};
    switch (setup) {
      case "published":
        userSetup = {
          defaultViewMode: "2D",
          enableChangeViewMode: true,
          enableCreateWalls: false,
          enableAddModules: false,
          enableSaveProject: false,
          enableCreateVariant: true,
          enablePublishing: false,
          enableViewSaves: true,
          enableViewVariants: true,
          enableCostEstimateUI: false,
          enableViewMenu: true,
        };
        yield put(projectState.setTool("view"));
        break;
      case "client":
        userSetup = {
          defaultViewMode: "2D",
          enableChangeViewMode: true,
          enableCreateWalls: false,
          enableAddModules: false,
          enableSaveProject: false,
          enableCreateVariant: false,
          enablePublishing: false,
          enableViewSaves: false,
          enableViewVariants: false,
          enableCostEstimateUI: false,
          enableViewMenu: false,
        };
        yield put(projectState.setTool("view"));
        break;
      default:
        userSetup = {
          defaultViewMode: "2D",
          enableChangeViewMode: true,
          enableCreateWalls: true,
          enableAddModules: true,
          enableSaveProject: true,
          enableCreateVariant: true,
          enablePublishing: true,
          enableViewSaves: true,
          enableViewVariants: true,
          enableCostEstimateUI: false,
          enableViewMenu: true,
        };
        break;
    }
    yield put(projectState.setUserSetup(userSetup));
    //yield put(projectState.setTool(userSetup.enableCreateWalls ? 'walls' : 'view'));
  } catch (error) {
    // console.log('Возникла проблема с fetch запросом: ', error.message);
    // return { 'ERR': 'errorload' };
  }
};

const sendEstimate = function* ({ estimate }) {
  try {
    yield put(projectState.addPreloader());

    yield fetch(window.confAjaxUrl, {
      method: "POST",
      body: JSON.stringify({
        method: "sendEstimate",
        estimate,
        token: window.rr_token,
        cache: false,
      }),
      headers: {
        "Content-Type": "application/json",
        application: "x-www-form-urlencoded",
      },
    });

    yield put(projectState.decPreloader());
  } catch (error) {
    // console.log('Возникла проблема с fetch запросом: ', error.message);
    // return { 'ERR': 'errorload' };
  }
};

async function loadModule(_obj) {
  let loader = null;
  if (/\.obj$/i.test(_obj.MODEL)) loader = new OBJLoader();
  if (/\.(gltf|glb)$/i.test(_obj.MODEL)) loader = new GLTFLoader();
  if (/\.fbx$/i.test(_obj.MODEL)) loader = new FBXLoader();
  if (loader) {
    const prom = new Promise((res) => {
      loader.load(_obj.MODEL, (obj) => {
        if (_obj.LINKED[0]) {
          const linked = _obj.LINKED[0];
          const MODEL = linked.MODEL;
          let loaderOpt = null;
          if (/\.obj$/i.test(MODEL)) loaderOpt = new OBJLoader();
          if (/\.(gltf|glb)$/i.test(MODEL)) loaderOpt = new GLTFLoader();
          if (/\.fbx$/i.test(MODEL)) loaderOpt = new FBXLoader();
          // console.log('obj',obj)
          // console.log('linked',linked)
          // console.log('MODEL',MODEL)

          loaderOpt.load(MODEL, (option) => {
            // console.log('option',option)
            let glb = false;
            if (/\.(gltf|glb)$/i.test(MODEL)) {
              option = option.scene;
              glb = true;
            }

            let z = 0,
              x_min = 0,
              x_max = 0,
              zo = 0,
              x_mino = 0,
              x_maxo = 0;
            obj.traverse((mesh) => {
              if (mesh.isMesh) {
                mesh.geometry.computeBoundingBox();
                if (mesh.geometry.boundingBox.max.z > z)
                  z = mesh.geometry.boundingBox.max.z;
                if (mesh.geometry.boundingBox.max.x > x_max)
                  x_max = mesh.geometry.boundingBox.max.x;
                if (mesh.geometry.boundingBox.min.x < x_min)
                  x_min = mesh.geometry.boundingBox.min.x;
              }
            });
            if (!glb) {
              option.traverse((mesh) => {
                if (mesh.isMesh) {
                  mesh.geometry.computeBoundingBox();
                  if (mesh.geometry.boundingBox.min.z < zo)
                    zo = mesh.geometry.boundingBox.min.z;
                  if (mesh.geometry.boundingBox.max.x > x_maxo)
                    x_maxo = mesh.geometry.boundingBox.max.x;
                  if (mesh.geometry.boundingBox.min.x < x_mino)
                    x_mino = mesh.geometry.boundingBox.min.x;
                }
              });
            }
            const g = new THREE.Group();
            g.add(option);
            if (!glb) {
              option.translateX((x_min + x_max + x_mino + x_maxo) / 2);
              option.translateZ(z - zo);
            }
            g.userData.type = "OPTION";
            g.visible = false;
            obj.add(g);
            res(obj);
          });
        } else {
          // console.log('obj',obj)
          if (/\.(gltf|glb)$/i.test(_obj.MODEL)) {
            obj = obj.scene;
          }
          obj.traverse((mesh) => {
            if (mesh.isMesh) {
              if (mesh.material.length > 0) {
                mesh.material.map((_m, i) => {
                  if (_m.name === "metal" || _m.name === "metall_stul") {
                    mesh.material[i] = window.materials.metal[0];
                  } else if (_m.name === "light") {
                    mesh.material[i] = window.materials.light[0];
                  }
                });
              } else if (
                mesh.material.name === "metal" ||
                mesh.material.name === "metall_stul"
              ) {
                mesh.material = window.materials.metal[0];
              } else if (mesh.material.name === "light") {
                mesh.material = window.materials.light[0];
              }
            }
          });
          res(obj);
        }
      });
    });
    const obj = await prom;

    const group = new THREE.Group();
    group.userData.type = "MODUL";
    group.add(obj);
    group.userData.animations = obj.animations;
    return new Module(group, _obj).clone();
  } else {
    const group = new THREE.Group();
    group.add(new THREE.Group());
    return new Module(group, _obj).clone();
  }
}

async function getMaterials({ result }) {
  const materialsObj = result;
  const matsAllProm = [];
  const matsKeys = [];

  const textureLoader1 = new THREE.TextureLoader();
  const textureEquirec1 = textureLoader1.load(
    window.confComponentUrl + "assets/lythwood_lounge.jpg"
  );
  textureEquirec1.mapping = THREE.EquirectangularReflectionMapping;
  textureEquirec1.magFilter = THREE.LinearFilter;
  textureEquirec1.minFilter = THREE.LinearMipMapLinearFilter;
  textureEquirec1.encoding = THREE.sRGBEncoding;
  textureEquirec1.anisotropy = 16;

  for (const materialType in materialsObj) {
    const materials = materialsObj[materialType];
    const matsProm = [];

    materials.map((m) => {
      const matProm = new Promise((resolve) => {
        const material = new THREE.MeshPhongMaterial(
          materialType === "floor" || materialType === "wall"
            ? { side: THREE.DoubleSide }
            : {}
        );
        material.perPixel = true;
        material.userData.ID = parseInt(m.ID);
        material.userData.IMAGE = m.IMAGE;
        material.userData.ICON = m.ICON;
        material.userData.PICT = m.PICT;
        material.userData.NAME = m.NAME;
        material.userData.DARK = m.DARK;
        material.name = materialType;
        material.shininess = m.SHININESS;
        if (/^[0-9a-fA-F]{6}$/.test(m.DIFFUSECOLOR)) {
          material.color = new THREE.Color(parseInt("0x" + m.DIFFUSECOLOR));
        }
        if (/^[0-9a-fA-F]{6}$/.test(m.SPECULAR)) {
          material.specular = new THREE.Color(parseInt("0x" + m.SPECULAR));
        }

        if (material.name === "face") {
          material.shininess = 50;
          material.envMap = textureEquirec1;
          material.reflectivity = 0.05;
          material.flatShading = true;
        } else if (material.name === "corp") {
          material.envMap = textureEquirec1;
          material.reflectivity = 0.03;
          material.flatShading = true;
        } else if (material.name === "wall") {
          material.flatShading = true;
        }
        material.castShadow = true;
        material.receiveShadow = true;

        if (materialType === "floor") {
          material.shininess = 100;
          material.reflectivity = 1;
          material.flatShading = true;
        }

        const maps = [];
        if (m.TEXTURE_MAP) {
          const textProm = new Promise((resolve) => {
            new THREE.TextureLoader().load(m.TEXTURE_MAP, (t) => {
              t.wrapS = t.wrapT = THREE.RepeatWrapping;
              t.encoding = THREE.sRGBEncoding;
              t.anisotropy = 16;
              resolve(t);
            });
          });
          maps.push(textProm);
        } else
          maps.push(
            new Promise((resolve) => {
              resolve(null);
            })
          );

        if (m.NORMAL_MAP) {
          const normProm = new Promise((resolve) => {
            new THREE.TextureLoader().load(m.NORMAL_MAP, (t) => {
              t.wrapS = t.wrapT = THREE.RepeatWrapping;
              t.encoding = THREE.sRGBEncoding;
              t.anisotropy = 16;
              resolve(t);
            });
          });
          maps.push(normProm);
        } else
          maps.push(
            new Promise((resolve) => {
              resolve(null);
            })
          );

        Promise.all(maps).then((t) => {
          if (t[0] !== null) material.map = t[0];
          if (t[1] !== null) material.normalMap = t[1];
          resolve(material);
        });
      });
      matsProm.push(matProm);
    });
    matsKeys.push(materialType);
    matsAllProm.push(Promise.all(matsProm));
  }
  const t_materials = await Promise.all(matsAllProm);
  const res = {};
  t_materials.map((m, i) => {
    res[matsKeys[i]] = m;
  });

  const textureLoader = new THREE.TextureLoader();
  const textureEquirec = textureLoader.load(
    window.confComponentUrl + "assets/Bright-roof-multi-1024x512.jpg"
  );
  textureEquirec.mapping = THREE.EquirectangularReflectionMapping;
  textureEquirec.magFilter = THREE.LinearFilter;
  textureEquirec.minFilter = THREE.LinearMipMapLinearFilter;
  textureEquirec.encoding = THREE.sRGBEncoding;
  const chrome = new THREE.MeshLambertMaterial({ envMap: textureEquirec });
  chrome.name = "metal";
  res.metal = [chrome];

  const light = new THREE.MeshLambertMaterial({
    emissive: new THREE.Color(0xffffff),
  });
  light.name = "light";
  res.light = [light];
  return res;
}

function removeParam(key, sourceURL) {
  var splitUrl = sourceURL.split("?"),
    rtn = splitUrl[0],
    param,
    params_arr = [],
    queryString = sourceURL.indexOf("?") !== -1 ? splitUrl[1] : "";
  if (queryString !== "") {
    params_arr = queryString.split("&");
    for (var i = params_arr.length - 1; i >= 0; i -= 1) {
      param = params_arr[i].split("=")[0];
      if (param === key) {
        params_arr.splice(i, 1);
      }
    }
    rtn = rtn + "?" + params_arr.join("&");
  }
  return rtn;
}

const dataSagaAsync = function* () {
  console.log("version 28/12/2024-1");
  yield takeEvery("GET_DATA", getData);
  yield takeEvery("LOAD_PROJECT_VARIANT", loadProjectVariant);
  yield takeEvery("MODULES/ADD_MODULE", addModule);
  yield takeEvery("SAVE_PROJECT", saveProject);
  yield takeEvery("AUTOSAVE_PROJECT", autoSaveProject);
  yield takeEvery("CHANGE_MODULE", changeModule);
  yield takeEvery("PUBLISH_VARIANT", publishVariant);
  yield takeEvery("SEND_ESTIMATE", sendEstimate);
  yield takeEvery("UPDATE_CURRENT_ESTIMATE", updateCurrentEstimate);
};

export default dataSagaAsync;
